import { STATIC_ITEM, DYNAMIC_ACCESS_SIZE, ITEMS_IMAGES_GRID } from '../../../../../utils/constants';

const { SMALL, MEDIUM, LONG } = DYNAMIC_ACCESS_SIZE;

const getSize = (isLong, isMedium) => {
  if (isLong) return LONG;
  if (isMedium) return MEDIUM;
  return SMALL;
};

const adjustCardHeight = (cards) => {
  const items = cards.filter(key => key.type === STATIC_ITEM || key.type === ITEMS_IMAGES_GRID);
  const { isLong, isMedium } = items.reduce(
    (acc, key) => {
      if (key.type === ITEMS_IMAGES_GRID) acc.isMedium = true;
      if (key.type === STATIC_ITEM) {
        if (key?.content?.action || key?.content?.shipping?.subText || key?.content?.highlight) {
          acc.isMedium = true;
        }

        const lengthPrice = key?.content?.price?.current_value?.value?.fraction?.length;
        acc.isLong = acc.isLong || (key?.content?.price?.discount && lengthPrice >= 10);
      }
      return acc;
    },
    { isLong: false, isMedium: false },
  );

  return getSize(isLong, isMedium);
};

export default adjustCardHeight;
